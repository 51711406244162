
import {Component, Vue} from 'vue-property-decorator';
import download from '@/infrastructure/api/Download';
import {loginModule} from '@/stores/authentication/Account';
import {progress} from '@/infrastructure/script/Progress';
import api from '@/infrastructure/api/API';
import {Material} from '@/types/material/Material';
import MaterialProgress from '@/views/material/MaterialProgress.vue';
import MaterialExplanation from '@/views/material/MaterialExplanation.vue';
import {ProgressStatus} from '@/types/content/ProgressStatus';
import ProgressStatusTag from '@/components/atoms/ProgressStatusTag.vue';
import {EmptyInformation, Information} from '@/types/information/Information';

@Component({
  components: {MaterialExplanation, MaterialProgress, ProgressStatusTag},
})
export default class Home extends Vue {
  // data
  materials: Material[] = [];
  informations: Information[] = [];

  // computed
  get isCompleted(): boolean {
    if (this.materials.length === 0) {
      return false;
    }
    return this.materials
      .filter((material) => material.id < 8000)
      .flatMap((material) => material.statuses)
      .every((status) => status === ProgressStatus.Learned || status === ProgressStatus.Exemption);
  }

  get isReadOnly(): boolean {
    return loginModule.isReadOnly;
  }

  get hasInformation(): boolean {
    return this.informations.length > 0;
  }

  get hasContact(): boolean {
    return loginModule.hasUnreadAnswer || loginModule.hasUserInquiry;
  }

  toInquiry(): void {
    this.$router.push({path: `/inquiry/`}).catch(() => {
      // @ts-ignore block is empty.
    });
  }

  // method
  async initialize(): Promise<void> {
    await this.getInformation();
    await this.getMaterials();
  }

  async getMaterials(): Promise<void> {
      await api.getMaterials()
        .then((response: any) => {
          this.materials = response;
        });
  }

  async registerCompletion(): Promise<void> {
    const registerCompletion = async (): Promise<void> => {
      await api.registerCompletion()
        .catch((error: any) => {
          // TODO エラー
          if (error.data.errors) {
            return;
          }
        });
    };

    await progress(registerCompletion);
  }

  async complete(): Promise<void> {
    if (!loginModule.hasCompletion) {
      await this.registerCompletion();
      await loginModule.getAccount();
    }
    this.$router.push({path: `/certificateComp/`}).catch(() => {
      // @ts-ignore block is empty.
    });
  }

  async getInformation(): Promise<void> {
    await api.getInformation()
      .then((response: Information[]) => {
        this.informations = response;
      });
  }

  getPrevMaterialStatus(index: number): string {
    if (this.materials[index - 1]) {
      return this.getMaterialNextStatus(this.materials[index - 1]);
    }
    return ProgressStatus.Learned;
  }

  allStatus(materials: Material[]): string {
    const local = this;
    let stat: string = ProgressStatus.Unlearned;
    materials.filter((material: Material) => {
      if (!(stat !== ProgressStatus.Unlearned && local.isMaterialUnlearned(material))) {
        stat = local.getMaterialNextStatus(material);
      }
    });
    return this.materialStatus(stat);
  }

  getMaterialNextStatus(material: Material|undefined): string {
    if (material === undefined) {
      return ProgressStatus.Learned;
    }
    return material.nextStatus;
  }
  materialStatus(status: string): string {
    if (status === ProgressStatus.Learning) {
      return '動画視聴が途中です、学習を再開してください';
    }
    if (status === ProgressStatus.Testing) {
      return '小テストを受けて次の動画学習へ進んでください';
    }
    if (status === ProgressStatus.Learned) {
      return '教材学習修了しました';
    }
    return '動画の視聴を開始してください';
  }
  isMaterialUnlearned(material: Material): boolean {
    if (!material) {
      return false;
    }
    return material.statuses.every((status) => {
      return (status === ProgressStatus.Unlearned);
    });
  }

  // lifecycle hooks
  created(): void {
    this.initialize();
  }
}
