
  import {Component, Prop, Vue} from 'vue-property-decorator';
  import {Material} from '@/types/material/Material';
  import download from '@/infrastructure/api/Download';

  @Component
  export default class MaterialExplanation extends Vue {

    @Prop()
    material!: Material | null;

    // method
    toContent(id: number): void {
      this.$router.push({path: `/content/${id}`}).catch(() => {
        // @ts-ignore block is empty.
      });
    }
  }
